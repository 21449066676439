// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contanos-tu-historia-js": () => import("./../../../src/pages/contanos-tu-historia.js" /* webpackChunkName: "component---src-pages-contanos-tu-historia-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lo-esencial-libertad-js": () => import("./../../../src/pages/lo-esencial-libertad.js" /* webpackChunkName: "component---src-pages-lo-esencial-libertad-js" */),
  "component---src-pages-mi-historia-js": () => import("./../../../src/pages/mi-historia.js" /* webpackChunkName: "component---src-pages-mi-historia-js" */),
  "component---src-pages-naturalmente-sobrenaturales-js": () => import("./../../../src/pages/naturalmente-sobrenaturales.js" /* webpackChunkName: "component---src-pages-naturalmente-sobrenaturales-js" */),
  "component---src-pages-no-se-admiten-personas-perfectas-js": () => import("./../../../src/pages/no-se-admiten-personas-perfectas.js" /* webpackChunkName: "component---src-pages-no-se-admiten-personas-perfectas-js" */)
}

